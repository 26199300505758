import * as React from "react"
import { graphql } from "gatsby"
import {getLanguage} from "../util/lang"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const FlipidiPost = ({ data, location }) => {
  let post = data.allMarkdownRemark.edges[0].node
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const lang = getLanguage(location.pathname);

  if (data.allMarkdownRemark.edges.length > 1) {
    post = (lang == 'es') ? data.allMarkdownRemark.edges[0].node : data.allMarkdownRemark.edges[1].node;
  }

  console.log('Flipidi post ?');
  console.log(data);

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr />
        <footer>
          <Bio />
        </footer>
      </article>
    </Layout>
  )
}

export default FlipidiPost

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
       filter: {
        frontmatter: {slug: { eq: $slug} }
      }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 160)
          html
          frontmatter {
            slug
            lang
            title
            date
          }
        }
      }
    }
  }
`
